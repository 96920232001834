<template>
  <div>
    <!-- :style="{ 'background-image': `url(${displayPoster})` }" -->
    <!-- :class="contentClass" -->
    <!--   :class="!isDisplayWatch
    ? 'content-card-div': ''"
    -->
    <div
      class="content-card"
      @click="actCardClicked"
      :class="orientation === 'LANDSCAPE' ? 'landscape-div' : 'potrait-div'"
    >
      <img
        :class="[
          'content-image',
          orientation === 'LANDSCAPE' ? 'landscape-div' : 'potrait-div',
        ]"
        :src="
          displayPoster
            ? displayPoster
            : orientation === 'LANDSCAPE'
            ? defaultPosterLandscape
            : defaultPosterPotrait
        "
        alt="Slider"
      />
      <div class="play-button">
        <img
          class="play-image"
          v-show="
            activeTabName === 'Trailers'
              ? true
              : activeTabName === 'Episodes'
              ? isDisplayWatch
              : false
          "
          @click.stop="playContent(content)"
          src="@/assets/icons/play-btn.svg"
          alt="play"
          loading="lazy"
        />
      </div>

      <div
        v-if="isDisplayLive"
        class="live-tag"
        v-text="isDisplayLive ? 'LIVE' : ''"
      ></div>

      <div class="view-count" v-if="viewCount && viewCount !== 0 && activeTabName === 'Episodes'" >
        <img :src="iconEyeOpenPath" alt="eye img" loading="lazy" />
        <p>{{ viewCount }} views</p>
      </div>
       <div class="background-gradient" v-if="viewCount && viewCount !== 0 && activeTabName === 'Episodes'"></div>
    </div>
    <div class="info">
      <div class="card-left">
        <div class="card-title" v-if="activeTabName === 'Episodes'">
          E{{ content.episodenum }} : {{ "\u00A0" }}{{ content.title }}
        </div>
        <!-- -if="activeTabName === 'Trailers'" -->
        <div class="card-title" v-else>{{ content.title }}</div>

        <div class="featured-tags" v-if="activeTabName != 'Trailers'">
          <div v-for="(tags, key) in featuredTags" :key="key">
            <p v-if="tags">{{ tags }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import FirebaseActions from "@/mixins/FirebaseActions";
import {getStartDate} from '@/mixins/dateSupport.js';

export default {
  props: {
    showContentDetailEvent: {
      type: Function,
    },
    content: {
      type: Object,
    },
    orientation: {
      type: String,
    },
    activeTabName: {
      type: String,
    },
    trailerPoster: {
      type: String,
    },
    isDisplayWatch: {
      type: Boolean,
      default: false,
    },
    routeCard: {
      type: Function,
      default: () => {},
    },
    closeDetailCard: {
      type: Function,
    },
    parentContent: {
      type: Object,
    },
  },
  data() {
    return {
      progress: null,
      showCompleted: false,
      showWatchlistBtn: false,
      showDownArrow: false,
      displayPoster: undefined,
      purchaseDetails: "",
      defaultPosterLandscape: require("@/assets/icons/placeholder-landscape.png"),
      defaultPosterPotrait: require("@/assets/icons/placeholder-potrait.png"),
      featuredTags: [],
      isDisplayLive: false,
      isDisplayEnded: false,
      viewCount: null,
      iconEyeOpenPath: require("@/assets/icons/detail_eye_open.svg"),
    };
  },
  computed: {
    ...mapGetters(["subscriberId", "ticketResponse", "appUser","appConfig"]),
    contentClass() {
      if (this.orientation === "LANDSCAPE") {
        return "content-card-land";
      } else if (this.orientation === "PORTRAIT") {
        return "content-card-port";
      }
      else return '';
    },
  },
  methods: {
    ...mapMutations(["commitCurrentContent"]),
    featuredTagsFun() {
      // let params = [];
      let _this = this;
      this.featuredTags = [];
      switch (this.content.contenttype) {
        case "EVENT":
          let startDate = getStartDate(this.content.fromdate);

          if (!startDate) {
            startDate = false;
          } else {
            let callFun = startDate.search("in");
            if (callFun) {
              let time = startDate.split(" ");
              if (time[2] !== "00:00:00") {
                setTimeout(function () {
                  _this.featuredTagsFun();
                }, 1000);
              }
            }
          }
          this.featuredTags.push(startDate);

          if (
            this.content.contenttype === "EVENT" &&
            this.content.premieringstatus !== "ANNOUNCED" &&
            this.content.premieringstatus !== "PREBOOK"
          ) {
            if (this.content.todate) {
              if (this.checkEventEnded(this.content.todate)) {
                this.isDisplayEnded = true;
                this.isDisplayLive = false;
              } else {
                this.isDisplayEnded = false;
                this.isDisplayLive = true;
              }
              // console.log(this.content, this.isDisplayEnded);
            } else {
              this.isDisplayEnded = false;
              this.isDisplayLive = true;
            }
          }
          // return params;
          break;
        case "LIVE":
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.getLanguage(this.content.language));
          // return params;
          break;
        case "MOVIE":
          let duration = this.getDuration(this.content.duration);
          this.featuredTags.push(this.content.category);
          // this.featuredTags.push(this.content.year);
          this.featuredTags.push(duration);
          // return params;
          break;
        case "SERIES":
          this.featuredTags.push(this.content.category);
          // this.featuredTags.push(this.content.year);
          if (this.content.episodecount) {
            this.featuredTags.push(this.content.episodecount + " Episodes");
          }
          // return params;
          break;
        case "TVSHOW":
          let showduration = this.getDuration(this.content.duration);
          this.featuredTags.push(this.content.category);
          if (showduration) this.featuredTags.push(showduration);
          break;
      }
    },
    getViewCount() {
      let obj = {};
      obj.contentid = this.content.contentid;
      obj.type = "views";
      obj.unique=this.appConfig.featureEnabled &&
              this.appConfig.featureEnabled.uniqueViewCount;

      this.fetchCountFirebase(obj)
        .then((response) => {
          if (!response.error) {
            this.viewCount = this.viewCountShort(response);
          }
        })
        .catch((error) => {
          console.log("error ", error);
          obj.unique=false; //fetch normal view if unique views not found.
          return this.fetchCountFirebase(obj)
        })
        .then((response)=>{
           if(!response)return;
           if (!response.error) {
               this.viewCount = this.viewCountShort(response);
            }
        })
        .catch((error) => {
          console.log("error ", error);
          obj.unique=false;
        });
    },
    actCardClicked() {
      console.log("entered");
      if (
        !this.isDisplayWatch &&
        this.activeTabName !== "Related" &&
        this.activeTabName !== "Trailers" &&
        !this.subscriberId
      ) {
        this.commitCurrentContent(this.parentContent);
        EventBus.$emit("LoginPopup");
        this.closeDetailCard();
      } else if (
        !this.isDisplayWatch &&
        this.activeTabName !== "Trailers" &&
        this.activeTabName !== "Related" &&
        this.subscriberId
      ) {
        this.routeCard();
      } else if (this.activeTabName === "Episodes" && this.isDisplayWatch) {
        this.playContent(this.content);
      } else if (this.activeTabName !== "Trailers") {
        // EventBus.$emit("openDetailCard", {detailCard:this.content,route:true});
          this.routeToCardView(this.content.contentid,true);
      }
    },
    checkPoster() {
      if (this.trailerPoster) {
        this.displayPoster = this.trailerPoster;
      } else {
        this.displayPoster = this.getPoster();
      }
    },
    getPoster() {
      if (this.content.posters && this.content.posters.length) {
        if (this.orientation === "LANDSCAPE") {
          let landscapeposters = this.content.posters.filter(
            (pos) => pos.orientation === "LANDSCAPE"
          );
          if (landscapeposters.length) {
            let posterIndex = landscapeposters.findIndex(
              (pos) => pos.quality === "SD"
            );
            if (posterIndex > -1) {
              return landscapeposters[posterIndex].url;
            } else {
              return landscapeposters[0].url;
            }
          }
        } else {
          let portraitPosters = this.content.posters.filter(
            (pos) => pos.orientation === "PORTRAIT"
          );
          // if (portraitPosters.length == 0)
          //   portraitPosters = this.content.posters.filter(
          //     pos => pos.orientation === "LANDSCAPE"
          //   );
          if (portraitPosters.length) {
            let posterIndex = portraitPosters.findIndex(
              (pos) => pos.quality === "SD"
            );
            if (posterIndex > -1) {
              return portraitPosters[posterIndex].url;
            } else {
              return portraitPosters[0].url;
            }
          }
        }
      } else {
        return this.content.poster;
      }
    },
    detailClick(event) {
      this.showContentDetailEvent();
      let current = document.getElementsByClassName("active-content");
      if (current.length) {
        current[0].classList.remove("active-content");
      }
      let element = event.target;
      element.classList.add("active-content");
    },
    removeActiveClass() {
      let current = document.getElementsByClassName("active-content");
      if (current.length) {
        current[0].classList.remove("active-content");
      }
    },
    playContent(content) {
      console.log("fromplay..", content.contenttype);
      EventBus.$emit("loadPlayer", content);
    },
  },
  created() {
    EventBus.$on("closeAllArrow", this.removeActiveClass);
    this.checkPoster();
  },
  mounted() {
    this.featuredTagsFun();
    if(this.activeTabName === 'Episodes'){
      this.getViewCount();
    }
  },
  mixins: [appMixins, FirebaseActions],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.content-card {
  position: relative;
  box-shadow: 0px 0px 10px $clr-color-dark-1;
  border-radius: 5px;
  height: 0;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  padding: 28.125% 0;

  &.landscape-div {
    padding: 28.125% 0;
  }
  &.potrait-div {
    padding: 75% 0;
  }

  .content-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .play-button {
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    position: absolute;
    transition: transform 0.3s ease;

    .play-image {
      width: 100%;
    }

    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  &:hover {
    background: linear-gradient(0deg, $clr-dark-hlt-2, transparent);
    transition: all 0.3s ease;
    border: 2px solid $theme-primary;
    // overflow: hidden;
  }
}
.content-card-div {
  cursor: pointer;
  position: relative;
}
// .landscape-div {
//   width: 235px;
//   height: 130px;
// }
// .potrait-div {
//   width: 235px;
//   height: 353px;
// }
.landscape-div,
.potrait-div {
  width: 100%;
}
.hover-effect {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  &:hover {
    background: linear-gradient(0deg, $clr-dark-hlt-2, transparent);
    transition: all 0.3s ease;
    border: 2px solid $theme-primary;
    border-radius: 12px;
  }
}
.info {
  margin-top: 5%;
}
.card-left {
  width: 100%;
  .card-title {
    font-size: 1.2rem;
    color: $font-clr-white;
    text-transform: capitalize;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .featured-tags {
    display: flex;
    margin-bottom: 12px;
    p {
      float: left;
      opacity: 0.76;
      color: $tag-primary;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-right: 4px;
    }
  }
  .featured-dec {
    margin: 0 0 1em 0;
    overflow: hidden;
    width: 100%;
    height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    p {
      float: left;
      opacity: 0.76;
      color: $font-clr-white-1;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
.active-content {
  border: 2px solid $theme-primary;
  border-radius: 12px;
  &::after {
    content: "";
    border-color: $theme-primary transparent transparent transparent;
    border-style: solid;
    border-width: 13px;
    position: absolute;
    bottom: -11%;
    left: 45%;
  }
}

.content-card .live-tag {
  position: absolute;
  top: 5px;
  right: 5px;
}
.live-tag {
  text-align: center;
  background: $clr-color-red 0% 0% no-repeat padding-box;
  border-radius: 2px;
  padding: 1px 5px;
  font-size: 0.625rem;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  color: $font-clr-white;
}

.view-count {
    position:absolute;
    bottom:5px;
    left:5px;
    height: max-content;
    text-align: center;
    p{
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    color: $font-clr-white;
    }
    display: flex;
    padding: 2px 3px;
    border-radius: 2px;
    align-items: flex-end;
    z-index: 999;
    img {
      margin-right: 5px;
      height: 0.8rem;
    }
  }
  .background-gradient {
    top: 0;
    left: 0;
    background: transparent
      linear-gradient(
        0deg,
        $clr-color-black-4 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 100%
      )
      0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    width: 100%;
  }

/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 576px) {
@include breakpoint(max600) {
  .content-card-port {
    .hover-effect {
      background: transparent;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 576px) and (max-width: 768px) {
@include breakpoint(min600max768) {
  .content-card-port {
    .hover-effect {
      background: transparent;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) and (max-width: 992px) {
@include breakpoint(min768max980) {
  .content-card-port {
    .hover-effect {
      background: transparent;
    }
  }
}
</style>