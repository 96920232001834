var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-card",class:_vm.orientation === 'LANDSCAPE' ? 'landscape-div' : 'potrait-div',on:{"click":_vm.actCardClicked}},[_c('img',{class:[
        'content-image',
        _vm.orientation === 'LANDSCAPE' ? 'landscape-div' : 'potrait-div' ],attrs:{"src":_vm.displayPoster
          ? _vm.displayPoster
          : _vm.orientation === 'LANDSCAPE'
          ? _vm.defaultPosterLandscape
          : _vm.defaultPosterPotrait,"alt":"Slider"}}),_c('div',{staticClass:"play-button"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.activeTabName === 'Trailers'
            ? true
            : _vm.activeTabName === 'Episodes'
            ? _vm.isDisplayWatch
            : false
        ),expression:"\n          activeTabName === 'Trailers'\n            ? true\n            : activeTabName === 'Episodes'\n            ? isDisplayWatch\n            : false\n        "}],staticClass:"play-image",attrs:{"src":require("@/assets/icons/play-btn.svg"),"alt":"play","loading":"lazy"},on:{"click":function($event){$event.stopPropagation();return _vm.playContent(_vm.content)}}})]),(_vm.isDisplayLive)?_c('div',{staticClass:"live-tag",domProps:{"textContent":_vm._s(_vm.isDisplayLive ? 'LIVE' : '')}}):_vm._e(),(_vm.viewCount && _vm.viewCount !== 0 && _vm.activeTabName === 'Episodes')?_c('div',{staticClass:"view-count"},[_c('img',{attrs:{"src":_vm.iconEyeOpenPath,"alt":"eye img","loading":"lazy"}}),_c('p',[_vm._v(_vm._s(_vm.viewCount)+" views")])]):_vm._e(),(_vm.viewCount && _vm.viewCount !== 0 && _vm.activeTabName === 'Episodes')?_c('div',{staticClass:"background-gradient"}):_vm._e()]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"card-left"},[(_vm.activeTabName === 'Episodes')?_c('div',{staticClass:"card-title"},[_vm._v(" E"+_vm._s(_vm.content.episodenum)+" : "+_vm._s("\u00A0")+_vm._s(_vm.content.title)+" ")]):_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.content.title))]),(_vm.activeTabName != 'Trailers')?_c('div',{staticClass:"featured-tags"},_vm._l((_vm.featuredTags),function(tags,key){return _c('div',{key:key},[(tags)?_c('p',[_vm._v(_vm._s(tags))]):_vm._e()])}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }